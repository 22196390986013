import { Box, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { Helmet } from "react-helmet"

import Grid from '@mui/material/Grid2'

import { Link, NavLink as RouterLink } from "react-router-dom"

import MainContainer from '../../components/Layout/MainContainer/MainContainer'
import VendorInventory from '../../components/VendorInventory/VendorInventory'
import Button from '@mui/material/Button'

import bgImage from './hempHarvestHeaderBG-full-fade.jpg'
import hempHarvestLogo from './hempharvestlogo-trans-400.png'
import hemphreyLogos from './all-around-hempstok-101824.png'

export default function HempHarvest2024() {
   const styles = {

      hempHarvestContainer: {
         backgroundImage: `url("${ bgImage }")`,
         backgroundSize: 'contain',
         backgroundPosition: 'top',
         backgroundRepeat: 'no-repeat',
         paddingTop: '7rem',
         paddingBottom: '48px',
         paddingRight: { xs: '1rem', sm: '2rem' },
         paddingLeft: { xs: '1rem', sm: '2rem' },
         backgroundColor: '#3d5125',
      },
      mainContainer: {
         backgroundColor: '#ffffffde',
         borderRadius: '28px',
         paddingTop: '4rem',
         paddingBottom: '2rem',
      },
      hempHarvestLogo: {
         width: '100%',
         maxWidth: '350px',
      },
      hempHarvestHeaderContainer: {
         borderRadius: '14px',
         marginBottom: '1rem',
      },
      hempHarvestHeader: {
         fontFamily: "'Kalam', cursive",
         fontSize: { 'sm': '2rem', 'md': '3rem' },
         fontWeight: '400',
         textAlign: 'center',
         color: 'secondary.main',
         maxWidth: '1200px',
         marginLeft: 'auto',
         marginRight: 'auto',
      },
      hempHarvestHeaderText: {
         fontSize: { 'xs': '1.3rem', 'sm': '1.6rem' },
         fontWeight: '400',
         textAlign: 'center',
         color: 'secondary.main',
         maxWidth: '900px',
         marginLeft: 'auto',
         marginRight: 'auto',
      },
      sectionBlockHeader: {
         marginTop: '2rem',
         marginBottom: '1rem',
         textAlign: { 'xs': 'center', 'sm': 'left' },
         color: 'white.main',
         padding: { xs: '1rem', sm: '1.5rem' },
         paddingLeft: { xs: '1rem', sm: '3rem' },
         fontFamily: "'Kalam', cursive",
         marginLeft: { xs: '0', sm: '-40px' },
         fontSize: { 'sm': '2rem', 'md': '2.5rem' },
      },
      footerContent: {
         backgroundColor: '#cbccc0',
         borderBottomLeftRadius: '28px',
         borderBottomRightRadius: '28px',
      },
      borderBG: {
         borderRadius: '28px',
         backgroundColor: '#ffffff',
         background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 50%)',
         padding: { xs: '1.5em', sm: '2rem' },
         marginTop: '2rem',
         // borderTop: '7px solid #7baa45',
      }
   }

   return (
      <>
         <Helmet>
            <meta charSet="utf-8" />
            <title>HempHarvest2024 - CBD Hemp Flower</title>
            <meta name="description" content="Follow along as we track the latest hemp flower drops from our favorite hemp farms for the 2024 harvest." />
         </Helmet>

         <MainContainer fullWidth={ true }>
            { /* Giveaway Banner */}
            {/*
            <Container
               maxWidth="xl"
               sx={ { display: 'flex', pt: 2, pb: 2, justifyContent: 'center', gap: 3 } }
            >
               <Typography
                  component="p"
                  variant="h5"
                  sx={ {
                     fontSize: { xs: '1rem', sm: '1.5rem' },
                  } }
               >
                  Win 4 Zips of Rogue Origin's Best 2023 Strains
               </Typography>
               <Box>
                  <Button
                     variant="contained"
                     component={ RouterLink }
                     color="secondary"
                     to="/ro-giveaway"
                  >
                     Enter
                  </Button>
               </Box>
            </Container>
            */ }

            <Box sx={ styles.hempHarvestContainer }>
               <Container maxWidth="xl" sx={ styles.mainContainer }>
                  <Grid container spacing={ 3 } alignItems="center" justifyContent="center" sx={ styles.hempHarvestHeaderContainer }>
                     <Grid size={ { sm: 12, md: 4 } } textAlign="center">
                        <Box
                           component="img"
                           src={ hempHarvestLogo }
                           sx={ styles.hempHarvestLogo }
                           alt="Hemp Harvest 2024 logo"
                        />
                     </Grid>
                     <Grid size={ { sm: 12, md: 8 } }>
                        <Typography
                           gutterBottom
                           variant='h1'
                           sx={ styles.hempHarvestHeader }
                        >
                           Follow the 2024 Hemp Harvest Season!
                        </Typography>
                        <Typography
                           gutterBottom
                           variant='body'
                           component={ 'p' }
                           sx={ styles.hempHarvestHeaderText }
                        >
                           It's fall, ya'll and you know what that means - the 2024 hemp harvest is in full swing! Follow along as we track the latest hemp flower drops for the season from our favorite hemp farms.
                        </Typography>
                     </Grid>
                  </Grid>

                  <VendorInventory mode="hh24" />

                  <Typography
                     variant='h2'
                     sx={ { ...styles.sectionBlockHeader, ...{ backgroundColor: '#e91e63' } } }
                  >
                     But, Wait! There's More (CBD Flower Strains)
                  </Typography>

                  <Box sx={ styles.borderBG }>
                     <Grid container spacing={ 7 } alignItems="center">
                        <Grid size={ { sm: 12, md: 5 } }>
                           <Box
                              component={ 'img' }
                              src={ hemphreyLogos }
                              alt="Hemphrey Logos"
                              sx={ { width: '100%' } }
                           />
                        </Grid>
                        <Grid size={ { sm: 12, md: 7 } }>
                           <Box>
                              <Typography
                                 variant="h1"
                                 component="h2"
                                 textAlign="center"
                              >
                                 Browse More Than 200 CBD Flower Strains From 12 Farms.
                              </Typography>
                              <Typography
                                 variant='h2'
                                 sx={ { marginTop: '2rem', marginBottom: '4rem' } }
                                 textAlign="center"
                              >
                                 View the in-stock strains from the top CBD farms across the nation in one platform.
                              </Typography>
                              <Box textAlign="center">
                                 <Button
                                    color="primary"
                                    size="large"
                                    variant='contained'
                                    sx={ { fontWeight: 'bold' } }
                                    component={ Link }
                                    to="/"
                                 >
                                    Browse More Strains
                                 </Button>
                              </Box>
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>
               </Container>
            </Box>
         </MainContainer>
      </>
   )
}
