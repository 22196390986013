import { useState, useEffect } from "react"

import { Box, Typography } from '@mui/material'
import { db, timestamp } from '../../firebase/config'

import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid2'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import Checkbox from '@mui/material/Checkbox'
import Switch from '@mui/material/Switch'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { Timestamp } from 'firebase/firestore'

import moment from 'moment'

import { collection, onSnapshot, query, where, getDocs } from 'firebase/firestore'

import {
   DataGrid,
   GridActionsCellItem,
   GridToolbarContainer,
   GridToolbarFilterButton
} from '@mui/x-data-grid'

import { useCollection } from '../../hooks/useCollection'

import { useDocument } from '../../hooks/useDocument'

import { doc, updateDoc } from 'firebase/firestore'

import Button from '@mui/material/Button'

import TextEditor from './TextEditor'

export default function ProductModal( { open, productId, vendor, handleModalClose } ) {   
   
   const [ photo, setPhoto ] = useState('')
   const [ title, setTitle ] = useState('')
   const [ hempType, setHempType ] = useState('')
   const [ description, setDescription ] = useState('')
   const [ effect, setEffect ] = useState('')
   const [ enviro, setEnviro ] = useState('')
   const [ shabazzReview, setShabazzReview ] = useState('')
   const [ ssoa, setSsoa] = useState('')
   const [ inputFields, setInputFields ] = useState([
      { key: '', value: '' }
   ] )
   const [ queue, setQueue ] = useState( '' )
   const [ logEntries, setLogEntries ] = useState('')
   const [ HH24, setHH24 ] = useState( '' )
   const [ inStock, setInStock ] = useState( '' )
   const [ newDate, setNewDate ] = useState( new Date() )
   const [ queueDate, setQueueDate ] = useState( null )
   const [ removeDate, setRemoveDate ] = useState( null )
   const [ updateDate, setUpdateDate ] = useState( null )

   const { document: productDoc } = useDocument( `vendors/${ vendor }/products`, productId )


   useEffect( () => {      
      if ( productDoc ) {
         const fetchLogEntries = async () => {

            // Get the update log for this product
            const q = query(
               collection( db, 'vendorUpdates' ),
               where( 'vendor_slug', '==', vendor ),
               where( 'pid', '==', productId )
            )

            // Getting called twice for some reason
            const querySnapshot = await getDocs( q )
      
            const results = []

            querySnapshot.forEach( ( doc ) => {
               results.push( { id: doc.id, ...doc.data() } )
            } )

            setLogEntries( results )
         }

         fetchLogEntries()

         setPhoto( productDoc.thumb_src || '' )
         setTitle( productDoc.title || '' )
         setHempType( productDoc.hemp_type || '' )
         setDescription( productDoc.description || '' )
         setEffect( productDoc.effect || '' )
         setEnviro( productDoc.enviro || '' )
         setShabazzReview( productDoc.shabazzreview || '' )
         setSsoa( productDoc.ssoa || '' )
         setInputFields( [] )
         setQueue( productDoc.queue || '' )
         setHH24( productDoc.hh24 || '' )
         setInStock( productDoc.in_stock || '' )
         
         setNewDate( productDoc.new_date.toDate() )
         setUpdateDate( productDoc.update_date.toDate() )

         if ( productDoc.queue_date ) {
            setQueueDate( productDoc.queue_date.toDate() )
         } else {
            setQueueDate( null )
         }

         if ( productDoc.removed_date ) {
            setRemoveDate( productDoc.removed_date.toDate() )
         } else {
            setRemoveDate( null )
         }

         const coa = []
         if ( productDoc.coa_url ) {
            for ( const [ key, value ] of Object.entries( productDoc.coa_url ) ) {
               coa.push( { key: key, value: value } )
            }

            setInputFields( coa )
         }
      }
   }, [ productId, productDoc ] )
   
   const handleSubmit = async ( e ) => {
      e.preventDefault()
      handleModalClose()
      const coaObject = {}
      inputFields.forEach( coa => {
         coaObject[ coa.key ] = coa.value
      } )

      const updateProduct = {
         thumb_src: photo,
         title: title,
         hemp_type: +hempType,
         coa_url: coaObject,
         description: description,
         effect: effect,
         enviro: enviro,
         shabazzreview: shabazzReview,
         ssoa: ssoa,
         queue: queue,
         hh24: HH24,
         in_stock: inStock,
         new_date: timestamp.fromDate( newDate ),
         update_date: timestamp.fromDate( updateDate )
      }

      if ( queueDate ) {
         updateProduct.queue_date = timestamp.fromDate( queueDate )
      }

      if ( removeDate ) {
         updateProduct.removed_date = timestamp.fromDate( removeDate )
      }
      
      await updateDoc( doc( db, `vendors/${ vendor }/products`, productId ), updateProduct )
   }

   const handleFormChange = ( index, e ) => {
      let data = [ ...inputFields ]
      data[ index ][ e.target.name ] = e.target.value
      setInputFields( data )
   }

   const addFields = ( e ) => {
      e.preventDefault()
      let newField = { key: '', value: '' }

      setInputFields( [ ...inputFields, newField ] )
   }

   const removeFields = ( e, index ) => {
      e.preventDefault()
      let data = [ ...inputFields ]
      data.splice( index, 1 )
      setInputFields( data )
   }

   const styles = {
      closeBtn: {
         position: 'absolute',
         right: 8,
         top: 8,
      },
   }

   if ( !productDoc ) {
      return ''
   }

   const columns = [
      // Type
      {
         field: 'type',
         headerName: 'Update Type',
         width: 100,
      },
   
      // Update Date
      {
         field: 'time',
         headerName: 'Update Date',
         width: 175,
         type: 'dateTime',
         valueGetter: ( value, row ) => row.time.toDate(),
      },
   
      { field: 'new_value', headerName: 'New Value', width: 150 },
      { field: 'old_value', headerName: 'Old Value', width: 150 },
   ]
   
   return (
      <Dialog
         fullWidth
         maxWidth="md"
         onClose={ handleModalClose }
         open={ open }
         scroll="body"
      >
         <DialogTitle>
            { productDoc ? `${ productDoc.title } by ${ productDoc.vendorName }` : '' }
            <Button
               variant="text"
               startIcon={ <OpenInNewIcon /> }
               href={ productDoc.profile_url }
               target="_blank"
               sx={ { marginLeft: '2em' } }
            >
               Open
            </Button>
         </DialogTitle>
         <IconButton
            onClick={ handleModalClose }
            sx={ styles.closeBtn }
         >
            <CloseIcon />
         </IconButton>
         
         <form onSubmit={ handleSubmit }>
            <DialogContent dividers>
               <Checkbox
                  checked={ inStock || false }
                  onChange={ ( e ) => setInStock( e.target.checked ) }
               />
               In Stok
            </DialogContent>

            <DialogContent dividers>
               <Typography gutterBottom variant="h6" color="secondary">Title:</Typography>

               <TextField
                  label="Title"
                  variant="outlined"
                  onChange={ ( e ) => setTitle( e.target.value ) }
                  value={ title }
                  margin="normal"
                  fullWidth
                  size="small"
               />
            </DialogContent>
            
            <DialogContent dividers>
               <Typography gutterBottom variant="h6" color="secondary">Photo:</Typography>

               <TextField
                  label="Photo"
                  variant="outlined"
                  onChange={ ( e ) => setPhoto( e.target.value ) }
                  value={ photo }
                  margin="normal"
                  fullWidth
                  size="small"
               />
            </DialogContent>
            
            <DialogContent dividers>
               <Typography gutterBottom variant="h6" color="secondary">Chemotype:</Typography>
               <FormControl fullWidth margin="normal">
                  <InputLabel id="chemotype-label">Chemotype</InputLabel>
                  <Select
                     labelId="chemotype-label"
                     id="chemotype-select"
                     value={ hempType }
                     label="Chemotype"
                     onChange={ ( e ) => setHempType( e.target.value ) }
                     size="small"
                  >
                     <MenuItem value={ '1' }>Type 1: THC</MenuItem>
                     <MenuItem value={ '2' }>Type 2: THC + CBD</MenuItem>
                     <MenuItem value={ '3' }>Type 3: CBD</MenuItem>
                     <MenuItem value={ '4' }>Type 4: CBG</MenuItem>
                  </Select>
               </FormControl>
            </DialogContent>

            <DialogContent dividers>
               <Typography gutterBottom variant="h6" color="secondary">COA Links:</Typography>
               { inputFields && inputFields.map( ( input, index ) => {
                  return (
                     <Grid
                        container
                        spacing={ 3 }
                        alignItems="center"
                        key={ index }
                     >
                        <Grid size={ { xs: 5 } }>
                           <Select
                              value={ input.key }
                              label="key"
                              onChange={ e => handleFormChange( index, e ) }
                              name="key"
                              size="small"
                              fullWidth
                           >
                              <MenuItem value={ 'Cannabinoids' }>Cannabinoids</MenuItem>
                              <MenuItem value={ 'Terpenes' }>Terpenes</MenuItem>
                              <MenuItem value={ 'View' }>View</MenuItem>
                              <MenuItem value={ 'Download' }>Download</MenuItem>
                           </Select>

                           { /*
                           <TextField
                              label="key"
                              value={ input.key }
                              onChange={ e => handleFormChange( index, e ) }
                              name="key"
                              fullWidth
                              size="small"
                           />
                           */}
                        </Grid>
                        <Grid size={ { xs: 5 } }>
                           <TextField
                              label="value"
                              value={ input.value }
                              onChange={ e => handleFormChange( index, e ) }
                              name="value"
                              fullWidth
                              size="small"
                           />
                        </Grid>
                        <Grid size={ { xs: 2 } }>
                           <IconButton
                              aria-label="remove"
                              variant="outlined"
                              onClick={ ( e ) => removeFields( e, index ) }
                              size="small"
                              color="pink"
                           >
                              <DeleteOutlineIcon />
                           </IconButton>
                        </Grid>
                     </Grid>
                  )
               } ) }

               <Button
                  variant="text"
                  onClick={ addFields }
               >
                  Add Field
               </Button>
            </DialogContent>

            <DialogContent dividers>
               <Typography gutterBottom variant="h6" color="secondary">Description:</Typography>
               <TextEditor content={ productDoc.description } setDescription={ setDescription } />
            </DialogContent>

            <DialogContent dividers>
            <Typography gutterBottom variant="h6" color="secondary">Effect:</Typography>
               <FormControl fullWidth margin="normal">
                  <InputLabel>Effect</InputLabel>
                  <Select
                     value={ effect }
                     label="Effect"
                     onChange={ ( e ) => setEffect( e.target.value ) }
                     size="small"
                  >
                     <MenuItem value={ 'indica' }>indica</MenuItem>
                     <MenuItem value={ 'indica-leaning' }>indica-leaning</MenuItem>
                     <MenuItem value={ 'hybrid' }>hybrid</MenuItem>
                     <MenuItem value={ 'sativa-leaning' }>sativa-leaning</MenuItem>
                     <MenuItem value={ 'sativa' }>sativa</MenuItem>
                  </Select>
               </FormControl>
            </DialogContent>

            <DialogContent dividers>
               <Typography gutterBottom variant="h6" color="secondary">Cultivation Environment:</Typography>
               <FormControl fullWidth margin="normal">
                  <InputLabel>Cultivation Environment</InputLabel>
                  <Select
                     value={ enviro }
                     label="Environmen"
                     onChange={ ( e ) => setEnviro( e.target.value ) }
                     size="small"
                  >
                     <MenuItem value={ 'outdoor' }>outdoor</MenuItem>
                     <MenuItem value={ 'greenhouse' }>greenhouse</MenuItem>
                     <MenuItem value={ 'indoor' }>indoor</MenuItem>
                  </Select>
               </FormControl>
            </DialogContent>

            <DialogContent dividers>
               <Typography gutterBottom variant="h6" color="secondary">Queue:</Typography>

               <FormControl fullWidth margin="normal">
                  <InputLabel>Queue</InputLabel>
                  <Select
                     value={ queue }
                     label="Queue"
                     onChange={ ( e ) => setQueue( e.target.value ) }
                     size="small"
                  >
                     <MenuItem value={ '' }>none</MenuItem>
                     <MenuItem value={ 'new' }>new</MenuItem>
                     <MenuItem value={ 'restock' }>restock</MenuItem>
                     <MenuItem value={ 'drop' }>drop</MenuItem>
                     <MenuItem value={ 'hide' }>hide</MenuItem>
                  </Select>
               </FormControl>
            </DialogContent>

            <DialogContent dividers>
               <Typography gutterBottom variant="h6" color="secondary">Shabazz Review:</Typography>

               <TextField
                  label="Shabazz Review URL"
                  variant="outlined"
                  onChange={ ( e ) => setShabazzReview( e.target.value ) }
                  value={ shabazzReview }
                  margin="normal"
                  fullWidth
                  size="small"
               />

               <Checkbox
                  checked={ ssoa || false }
                  onChange={ ( e ) => setSsoa( e.target.checked ) }
               />
               Shabazz Seal of Approval
            </DialogContent>

            <DialogContent dividers>
               <Typography gutterBottom variant="h6" color="secondary">Other Settings:</Typography>

               <Checkbox
                  checked={ HH24 || false }
                  onChange={ ( e ) => setHH24( e.target.checked ) }
               />
               HH24
            </DialogContent>
         
            <DialogContent dividers>
               <Typography gutterBottom variant="h6" color="secondary">Vendor Updates:</Typography>

               { logEntries && (
               <DataGrid
                  rows={ logEntries }
                  columns={ columns }
                  initialState={{
                     pagination: {
                        paginationModel: { page: 0, pageSize: 100 },
                     },
                     sorting: {
                        sortModel: [{ field: 'time', sort: 'desc' }],
                     },
                  }}
                  pageSizeOptions={ [ 100 ] }
               />
               ) }
            </DialogContent>

            <DialogContent dividers>
               <Typography gutterBottom variant="h6" color="secondary">Dates:</Typography>

               <Grid spacing={ 2 } container>
                  <Grid size={ { xs: 12, sm: 3 } }>
                     <LocalizationProvider dateAdapter={ AdapterMoment }>
                        <DateTimePicker
                           value={ moment( newDate ) }
                           onChange={ ( newValue ) => {
                              setNewDate( newValue.toDate() )
                           } }
                           label="New"
                           views={ ['year', 'month', 'day', 'hours', 'minutes', 'seconds'] }
                        />
                     </LocalizationProvider>
                  </Grid>

                  <Grid size={ { xs: 12, sm: 3 } }>
                     <LocalizationProvider dateAdapter={ AdapterMoment }>
                        <DateTimePicker
                           value={ moment( updateDate ) }
                           onChange={ ( updateValue ) => {
                              setUpdateDate( updateValue.toDate() )
                           } }
                           label="Updated"
                           views={ ['year', 'month', 'day', 'hours', 'minutes', 'seconds'] }
                        />
                     </LocalizationProvider>
                  </Grid>

                  <Grid size={ { xs: 12, sm: 3 } }>
                     <LocalizationProvider dateAdapter={ AdapterMoment }>
                        <DateTimePicker
                           slotProps={ { field: { clearable: true } } }
                           value={ queueDate ? moment( queueDate ) : null }
                           onChange={ ( queueValue ) => {
                              setQueueDate( queueValue ? queueValue.toDate() : null )
                           } }
                           label="Queue"
                           views={ ['year', 'month', 'day', 'hours', 'minutes', 'seconds'] }
                        />
                     </LocalizationProvider>
                  </Grid>

                  <Grid size={ { xs: 12, sm: 3 } }>
                     <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                           slotProps={ { field: { clearable: true } } }
                           value={ removeDate ? moment( removeDate ) : null }
                           onChange={ ( removeValue ) => {
                              setRemoveDate( removeValue ? removeValue.toDate() : null )
                           } }
                           label="Removed"
                           views={ ['year', 'month', 'day', 'hours', 'minutes', 'seconds'] }
                        />
                     </LocalizationProvider>
                  </Grid>
               </Grid>

            </DialogContent>

            <DialogActions>
               <Button variant="contained" type="submit">Update</Button>
               <Button
                  variant="outlined"
                  onClick={ ( e ) => {
                     e.preventDefault()
                     handleModalClose()
                  } }
                  ml={ 2 }
               >
                  Cancel
               </Button>
            </DialogActions>
            { /* error && <p>{ error }</p> */}
         </form>
      </Dialog>
   )
}
